import React from "react";
import { imgPath } from "helpers/path";
import VerifiedIcon from "@mui/icons-material/Verified";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { RiseLoader } from "react-spinners";

const ProductCard = ({ onDetails, data, loader }) => {
  console.log(data, "data");
  return (
    <div className="shadow-card rounded-lg">
      <div className="p-2 flex justify-end">
        {data.isVerified ? (
          <VerifiedIcon style={{ color: "#668968" }} />
        ) : (
          <UnpublishedIcon style={{ color: "#668968" }} />
        )}
      </div>
      <div className="py-2">
        <img
          className="max-h-[150px] min-h-[150px] max-w-[150px] min-w-[150px] rounded-full mx-auto"
          draggable={false}
          src={
            data.image && data.image.length > 0 && `${imgPath}${data.image[0]}`
          }
          alt="avatar"
        />
      </div>
      <div className="p-3 leading-6">
        <p className="font-RobotoBold text-primary text-[16px] truncate">
          Name:{" "}
          <span className="text-black font-Roboto capitalize">
            {data.name && data.name}
          </span>
        </p>
        <p className="font-RobotoBold text-primary text-[16px] truncate">
          Category:{" "}
          <span className="text-black font-Roboto capitalize ">
            {data.ProductType && data.ProductType}
          </span>
        </p>
        <p className="font-RobotoBold text-primary text-[16px] truncate">
          SubCategory:{" "}
          <span className="text-black font-Roboto capitalize ">
            {data.subProductType ? data.subProductType : "N/A"}
          </span>
        </p>
        <p className="font-RobotoBold text-primary text-[16px] truncate">
          Brand:{" "}
          <span className="text-black font-Roboto capitalize ">
            {data.brand ? data.brand : "N/A"}
          </span>
        </p>
      </div>
      <button
        disabled={loader}
        onClick={() => onDetails(data)}
        className="bg-gradient w-full h-[50px] rounded-b-lg flex justify-center items-center cursor-pointer"
      >
        {!loader ? (
          <p className="text-white font-semibold font-RobotoBold text-[16px]">
            Details
          </p>
        ) : (
          <RiseLoader color="#ffffff" size={14} />
        )}
      </button>
    </div>
  );
};

export default ProductCard;
