import React from "react";
import { Button } from "components/common/base/button";
import FormInput from "components/common/base/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { LoginSchema } from "helpers/schema";
import { useWindowSize } from "react-use";

const LoginForm = ({ onSubmit, loader }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const { width } = useWindowSize();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-5 flex flex-col items-center justify-center w-full">
        <div className="w-[80%]">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormInput
                {...register("email")}
                placeholder="Enter your email"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={errors?.email && errors.email.message}
              />
            )}
          />
        </div>
        <div className="mt-3 w-[80%]">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormInput
                {...register("password")}
                placeholder="Enter your password"
                type="password"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                error={errors?.password && errors.password.message}
              />
            )}
          />
        </div>
        <div className="my-8">
          <Button
            width={width > 400 ? 150 : 130}
            height={width > 400 ? 50.5 : 50}
            variant="primary"
            value="Login"
            type="submit"
            disabled={loader}
            loader={loader}
          />
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
