import React from 'react'
import Layout from "layout/MainLayout"

const Composition = () => {
  return (
    <Layout>
    <div>
      <p>This is a composition page</p>
    </div>
    </Layout>
  )
}

export default Composition
